import { getFrameworkServiceUrl, AuthState, fawkesUtils } from '@sparky/framework'

const Gateways = {
  'api_gateway': 'api_gateway',
  'api_gateway_global_service': 'api_gateway_global_service',
  'api_gateway_gov': 'api_gateway_gov'
} 

export const getZycadaDomain = ( authState: AuthState ): string | undefined => {
  let domain: string | undefined = '';

  let region = authState.instances.getPlatformRegion();
  region = region ?? Gateways.api_gateway;
  region = (region.indexOf('gov') === -1) ? Gateways.api_gateway : Gateways.api_gateway_gov;

  domain = getFrameworkServiceUrl(region);
  return domain;
}

export const getFawkesDomain = (  ): string | undefined => {
  const domain = getFrameworkServiceUrl(Gateways.api_gateway);
  return domain;
}

export const getFawkesCertsDomain = ( ): string | undefined => {
  return fawkesUtils.getRedirectURL();
  // return authState?.fawkes_paas_api_url 
}

export const isValidCertName = (cert: string): boolean => {
  let modCert = cert;
  
  if (cert.startsWith('*.')) {
    modCert = modCert.slice(2);
  }
  return isValidIpAddrFQDN(modCert);
}

export const isValidIpAddrFQDN = (addr: string): boolean => {
  if (isValidIPv4(addr)) {
    return true;
  } else if (isValidDomainName(addr)) {
    return true;
  }
  return false;
}

export const isValidIPv4 = (addr: string): boolean => {
    const regex = /^((25[0-5]|2[0-4][0-9]|[0-1]?[0-9]?[0-9])\.){3}(25[0-5]|2[0-4][0-9]|[0-1]?[0-9]?[0-9])$/;
    return regex.test(addr);
}

const isValidDomainName = (name: string): boolean =>{
   const regex = /^((www\.([a-zA-Z0-9-]{2,63}\.)+[a-zA-Z]{2,63})|(([a-zA-Z0-9-]{2,63}\.)+[a-zA-Z]{2,63}))(\.[a-zA-Z]{2,63})?$/;
   return regex.test(name);
}

export function toBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = reject;
    });
}

// Function to convert kebab case to title case
export const kebabToTitleCase = (str: string): string => {
    return str
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
};


export function dataURLtoFile(dataurl: string, filename: string): File {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)?.[1] || '';
    const bstr = atob(arr[arr.length - 1]);
    const n = bstr.length;
    const u8arr = new Uint8Array(n);
    
    for (let i = 0; i < n; i++) {
        u8arr[i] = bstr.charCodeAt(i);
    }

    return new File([u8arr], filename, { type: mime });
}

const DEFAULT_DEBOUNCE_DELAY = 500;

export function debounce<T extends (...args: Parameters<T>) => ReturnType<T>>(
    func: T,
    delay: number = DEFAULT_DEBOUNCE_DELAY
): (...args: Parameters<T>) => void {
    let timeoutId: ReturnType<typeof setTimeout> | undefined;

    return (...args: Parameters<T>) => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        timeoutId = setTimeout(() => {
            func(...args);
        }, delay);
    };
}
