export enum Level {
    FATAL,
    ERROR,
    WARN,
    INFO,
    DEBUG,
    TRACE,
}

type consoleLogTypes = {
    [logType: string]: (...args: unknown[]) => void
}

const __console: consoleLogTypes = {
    info: (...args: unknown[]): void => {
        console.info(...args);
    },
    debug: (...args: unknown[]): void => {
        console.debug(...args);
    },
    warn: (...args: unknown[]): void => {
        console.warn(...args);
    },
    error: (...args: unknown[]): void => {
        console.error(...args);
    },
    trace: (...args: unknown[]): void => {
        console.trace(...args);
    }
  }


export const LogLevel = Level;

// const dateFormatOptions = {
//     year: "numeric",
//     month: "short",
//     day: "numeric",
//     hour: "2-digit",
//     minute: "2-digit",
//     second: "2-digit"
// }

export interface LoggerFunctions {
    setLevel(level: Level): void; 
    log(message: string, ...args: unknown[]): void; 
    fatal(message: string, ...args: unknown[]): void; 
    error(message: string, ...args: unknown[]): void; 
    warn(message: string, ...args: unknown[]): void; 
    info(message: string, ...args: unknown[]): void; 
    debug(message: string, ...args: unknown[]): void; 
    trace(message: string, ...args: unknown[]): void; 
}

const LoggerSingleton = (() => {
    let logger: LoggerFunctions| undefined = undefined;

    function init() {
        let level = Level.DEBUG;

        const logToConsole = (message: string, level: Level, ...args: unknown[]) => {
            if (typeof console !== "undefined" && shouldLog(level)) {
              const logLevelName = Level[level].toLowerCase();
              const formattedMessage = `${new Date().toLocaleString()} [${logLevelName}] ${message}`;
              try {
                __console[logLevelName](formattedMessage, ...args);
              } catch (e) {
                console.info(formattedMessage, ...args);
              }
            }
          };  

        const shouldLog = (currentLevel: Level): boolean => {
            return currentLevel <= level;
        }

        return {
            setLevel(alevel: Level): void {
                level = alevel;
            },

            log(message: string, ...args: unknown[]): void {
                if (level >= 4) {
                    logToConsole(message, Level.DEBUG, ...args)
                }
            },

            fatal(message: string, ...args: unknown[]): void {
                logToConsole(message, Level.FATAL, ...args);
            },

            error(message: string, ...args: unknown[]): void {
                logToConsole(message, Level.ERROR, ...args);
            },

            warn(message: string, ...args: unknown[]): void {
                logToConsole(message, Level.WARN, ...args);
            },

            info(message: string, ...args: unknown[]): void {
                logToConsole(message, Level.INFO, ...args);
            },

            debug(message: string, ...args: unknown[]): void {
                logToConsole(message, Level.DEBUG, ...args);
            },

            trace(message: string, ...args: unknown[]): void {
                logToConsole(message, Level.TRACE, ...args);
            }
        }
    }

    return {
        getInstance: () => {
            if (logger === undefined) {
                logger = init()
            }

            return logger;
        }
    }
})()

const Logger = LoggerSingleton.getInstance()
export default Logger;
export { Logger }
