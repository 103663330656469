export const makeQueryParams = (url: string, params: Record<string, unknown> | string): string => {
    let paramStr = '';
    
    if (typeof params === 'object') {
        const paramKeys = Object.keys(params);
        for (let i = 0, len = paramKeys.length; i < len; i++) {
            if (paramStr.length > 0) {
            paramStr += '&';
            }
            const key = paramKeys[i];
            paramStr += `${key}=${new URLSearchParams((params[key] as string).toString())}`;
        }
    } else if (typeof params === 'string') {
        paramStr = params;
    }

    return `${url}?${paramStr}`;
}

export const cieRequestBody = {
        "attrName": [
            "SAM Account Name",
            "User Principal Name"
        ]
}

export const PraUrls = {
    praBaseUrl:             '/workflows/privileged-remote-access',
    praActiveSessions:      '/workflows/privileged-remote-access/active-sessions',
    praStatus:              '/workflows/privileged-remote-access/pra-status',
    praStatusSettings:      '/workflows/privileged-remote-access/pra-status/settings',
    praProfiles:            '/workflows/privileged-remote-access/profiles',
    praAddProfile:          '/workflows/privileged-remote-access/profiles/add-profile',
    praEditProfile:         '/workflows/privileged-remote-access/profiles/edit-profile',
    praApplications:        '/workflows/privileged-remote-access/applications',
    praAppGroups:           '/workflows/privileged-remote-access/applications?groups=true',
    praCreateApp:           '/workflows/privileged-remote-access/applications/add-application',
    praEditApp:             '/workflows/privileged-remote-access/applications/edit-application',
    praCreateAppGroup:      '/workflows/privileged-remote-access/applications/create-app-group',
    praEditAppGroup:        '/workflows/privileged-remote-access/applications/edit-app-group',
    praPortal:              '/workflows/privileged-remote-access/portal',
    praPortalSettingsEdit:  '/workflows/privileged-remote-access/portal/settings-edit',
    praCreatePolicy:        '/workflows/privileged-remote-access/portal/create-policy',
    praEditPolicy:          '/workflows/privileged-remote-access/portal/edit-policy',

    // Environment modifiers
    praUrlModifier:     '/sse/agentless_uda/v1.0/',
    praUrlModifierStg1: '/sse/agentless_uda/v1.0/stg1/v1.0/',
    praUrlModifierStg2: '/sse/agentless_uda/v1.0/stg2/v1.0/',

    praSessionUrlModifier: `/sse/agentless_uda/session_info/`,
    praSessionUrlModifierStg1: `/sse/agentless_uda/stg1/session_info/`,
    praSessionUrlModifierStg2: `/sse/agentless_uda/stg2/session_info/`,

    // get data from our apiGee
    // serverUDADomains: 'domains',
    // serverUDADomain: 'domain',

    // has fawkes enabled us?
    serverFawkesCloudServices: 'sase/config/v1/cloud-services',
    serverFawkesCertInfo: '/api/config/v9.2/Device/CertificateManagement/CertificatesInfo?type=container&folder=Mobile%20Users%20Container&isBpa=no',

    // serverCieUsers: 'api/config/v9.2/complete',
    serverCieUsers: 'api/directory/v1/groupsAndUsers',

    // APIGee endpoints:
    serverAddParam: (url: string, param: string) => {return `${url}/${param}`; },
    serverPraApps: 'privileged_remote_access/apps',
    serverPraPolicies: 'privileged_remote_access/app_policies',
    serverPraProfiles: 'privileged_remote_access/app_profiles',

    serverPraPortalInfo: 'privileged_remote_access/settings',
    serverPraAppGroups:  'privileged_remote_access/app_groups',
    serverPraSessions:    'v1/sessions',

    // TODO: may not be needed for PRA
    sparkyCertManagement: '/manage/objects/certificate-management?container=mobile-users',
}

export type ApiGeeEnv = {
    zos: string,
    session: string,
}

export const apiGeeEnvData: ApiGeeEnv[] = [
    { zos: PraUrls.praUrlModifier, session: PraUrls.praSessionUrlModifier},
    { zos: PraUrls.praUrlModifierStg1, session: PraUrls.praSessionUrlModifierStg1},
    { zos: PraUrls.praUrlModifierStg2, session: PraUrls.praSessionUrlModifierStg2},
];

export default PraUrls;
