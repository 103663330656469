import { MessageDescriptor } from 'react-intl';

export const en_strs: Record<string, MessageDescriptor> = {
    praApplicationTitle: {
      id: 'praTitle',
      defaultMessage: 'Privileged Remote Access',
    },
    praApplicationsTitle: {
      id: 'praApplicationsTitle',
      defaultMessage: 'Applications',
    },
    praAppGroupTab: {
      id: 'praAppGroupTab',
      defaultMessage: 'Applications Groups',
    },

    praApplicationsTab: {
      id: 'praApplicationsTab',
      defaultMessage: 'Applications',
    },

    praApplications: {
      id: 'praApplications',
      defaultMessage: 'Applications'
    },
    praActiveSessions: {
      id: 'praActiveSessions',
      defaultMessage: 'Active Sessions'
    },
    praAddAppBtn: {
      id: 'praAddAppBtn',
      defaultMessage: 'Add Application'
    },
    praAddAppAriaLabel: {
      id: 'praAddAppAriaLabel',
      defaultMessage: 'Add new application domain'
    },
    praDisconnectBtn: {
      id: 'praDisconnectBtn',
      defaultMessage: 'Disconnect'
    },
    praAppNameCol: {
      id: 'praAppColName',
      defaultMessage: 'Name'
    },
    praUserNameCol: {
      id: 'praUserNameCol',
      defaultMessage: 'User Name'
    },
    praAppTypeCol: {
      id: 'praAppColType',
      defaultMessage: 'App Type'
    },
    praAppDestinationCol: {
      id: 'praAppColDestination',
      defaultMessage: 'FQDN/IP Address'
    },
    praAppPortCol: {
      id: 'praAppColPort',
      defaultMessage: 'Port'
    },
    praAppMaxSessionsCol: {
      id: 'praAppColMaxSessions',
      defaultMessage: 'Max concurrent connections.'
    },
    praAppDescriptionCol: {
      id: 'praAppColDescription',
      defaultMessage: 'Description'
    },
    praActionsCol: {
      id: 'praColActions',
      defaultMessage: 'Actions'
    },

    // Strings for App Portal
    praAppPortals: {
      id: 'praAppPortals',
      defaultMessage: 'App Portals'
    },

    // Strings for Active Sessions
    praActiveSessionsTitle: {
      id: 'praActiveSessionsTitle',
      defaultMessage: 'Active Sessions',
    },
    praRefreshSessions: {
      id: 'praRefreshSessions',
      defaultMessage: 'Refresh'
    },
    praSourceIpCol: {
      id: 'praSourceIpCol',
      defaultMessage: 'Source IP',
    },
    praDeviceTypeCol: {
      id: 'praDeviceTypeCol',
      defaultMessage: 'Device Type',
    },
    praActiveBrowserCol: {
      id: 'praActiveBrowserCol',
      defaultMessage: 'Browser/Version',
    },
    praStartTimeCol: {
      id: 'praStartTimeCol',
      defaultMessage: 'Start Time'
    },
    praLastActiveCol: {
      id: 'praLastActiveCol',
      defaultMessage: 'Last Active'
    },
    praRefreshAsOf: {
      id: 'praRefreshAsOf',
      defaultMessage: 'Refreshed as of '
    },    
    praConfirmDisconnect: {
      id: 'praConfirmDisconnect',
      defaultMessage: 'Confirm Disconnect'
    },
    praSessionDisconnectConfirmation: {
      id: 'praSessionDisconnectConfirmation',
      defaultMessage: 'Are you sure you want to disconnect this session?',
    },
    praSelectedSessionsDisconnectConfirmation: {
      id: 'praSelectedSessionsDisconnectConfirmation',
      defaultMessage: 'Are you sure you want to disconnect selected sessions?',
    },
    praSessionsDisconnectSuccess: {
      id: 'praSessionsDisconnectSuccess',
      defaultMessage: 'Session(s) disconnected successfully',
    },
    praSessionsDisconnectFail: {
      id: 'praSessionsDisconnectFail',
      defaultMessage: 'Failed to disconnect Session(s)',
    },
    

    // Strings for Profiles Table/Create/Edit app
    praProfilesTitle: {
      id: 'praProfilesTitle',
      defaultMessage: 'PRA Profiles',
    },
    praProfilesDeletionModalTitle: {
      id: 'praProfilesDeletionModalTitle',
      defaultMessage: 'Confirm deletion',
    },
    praDeleteProfileAriaLabel: {
      id: 'praDeleteProfileAriaLabel',
      defaultMessage: 'delete profile',
    },
    praEditProfileAriaLabel: {
      id: 'praEditProfileAriaLabel',
      defaultMessage: 'edit profile',
    },
    praAddProfileBtn: {
      id: 'praAddProfileBtn',
      defaultMessage: 'Add Profile',
    },
    praAddProfileTitle: {
      id: 'praAddProfileTitle',
      defaultMessage: 'Add Profile',
    },
    praEditProfileTitle: {
      id: 'praEditProfileTitle',
      defaultMessage: 'Edit Profile',
    },
    praProfileSessionAppTitle: {
        id: 'praProfileSessionRdpTitle',
        defaultMessage: '{app} Session Profile'
    },
    praProfileAddSuccess: {
        id: 'praProfileAddSuccess',
        defaultMessage: 'Profile added successfully'
    },
    praProfileAddFail: {
        id: 'praProfileAddFail',
        defaultMessage: 'Failed to add profile'
    },
    praProfileEditSuccess: {
        id: 'praProfileEditSuccess',
        defaultMessage: 'Profile edited successfully'
    },
    praProfileEditFail: {
        id: 'praProfileEditFail',
        defaultMessage: 'Failed to edit profile'
    },
    praProfileDeleteSuccess: {
        id: 'praProfileDeleteSuccess',
        defaultMessage: 'Profile {profileName} deleted successfully',
    },
    praProfileDeleteFail: {
        id: 'praProfileDeleteFail',
        defaultMessage: 'Failed to delete profile {profileName}',
    },
    praProfileDeleteConfirm: {
        id: 'praProfileDeleteConfirm',
        defaultMessage: 'Do you really want to delete the profile',
    },
    praProfileResetTitle: {
        id: 'praProfileResetTitle',
        defaultMessage: 'Reset Profile Settings',
    },
    praProfileResetSubTitle: {
        id: 'praProfileResetSubTitle',
        defaultMessage: `Restore this PRA profile's settings to its initial values.`,
    },
    praProfileGeneralTitle: {
        id: 'praProfileGeneralTitle',
        defaultMessage: 'General',
    },
    praProfileSessionTitle: {
        id: 'praProfileSessionTitle',
        defaultMessage: 'PRA Session Profile',
    },

    // Strings for Controls
    praControlPrint: {
      id: 'praControlPrint',
      defaultMessage: 'Print',
    },
    praControlCopy: {
      id: 'praControlCopy',
      defaultMessage: 'Copy',
    },
    praControlPaste: {
      id: 'praControlPaste',
      defaultMessage: 'Paste',
    },
    praControlFileUpload: {
      id: 'praControlFileUpload',
      defaultMessage: 'File Upload',
    },
    praControlFileDownload: {
      id: 'praControlFileDownload',
      defaultMessage: 'File Download',
    },
    praControlSftpTransfer: {
      id: 'praControlSftpTransfer',
      defaultMessage: 'SFTP Transfer',
    },
    praControlSftpReadonly: {
        id: 'praControlSftpReadonly',
        defaultMessage: 'Read-only',
    },


    praProfileNameCol: {
      id: 'praProfileNameCol',
      defaultMessage: 'Profile Name',
    },
    praProfileDescriptionCol: {
      id: 'praProfileDescriptionCol',
      defaultMessage: 'Description',
    },
    praProfileRDPControlsCol: {
      id: 'praProfileRDPControlsCol',
      defaultMessage: 'RDP Controls',
    },
    praProfileSSHControlsCol: {
      id: 'praProfileSSHControlsCol',
      defaultMessage: 'SSH Controls',
    },
    praProfileVNCControlsCol: {
      id: 'praProfileVNCControlsCol',
      defaultMessage: 'VNC Controls',
    },
    praProfileActionsCol: {
      id: 'praProfileActionsCol',
      defaultMessage: 'Actions',
    },
    
    
    // Strings for Create/Edit app
    praAddApplication:  {
      id: 'praAddApplication',
      defaultMessage: 'Add Application',
    },
    praEditApplication:   {
      id: 'praEditApplication',
      defaultMessage: 'Edit Application',
    },
    praDeleteApplication:   {
      id: 'praDeleteApplication',
      defaultMessage: 'Delete Application',
    },
    praGeneralSettings: {
      id: 'praGeneralSettings',
      defaultMessage: 'General Settings',
    },
    praProtocol: {
      id: 'praProtocol',
      defaultMessage: 'Protocol',
    },
    praName: {
      id: 'praName',
      defaultMessage: 'Name',
    },
    praNamePlaceHolder: {
      id: 'praNamePlaceHolder',
      defaultMessage: 'Enter your app name',
    },
    praDescription: {
      id: 'praDescription',
      defaultMessage: 'Description',
    },
    praDescriptionPlaceHolder: {
      id: 'praDescriptionPlaceHolder',
      defaultMessage: 'Short description about this app',
    },
    praDescriptionHint: {
      id: 'praDescriptionHint',
      defaultMessage: 'Description text goes here',
    },
    praDestination: {
      id: 'praDestination',
      defaultMessage: 'Destination',
    },
    praDestinationPlaceholder: {
      id: 'praDestinationPlaceholder',
      defaultMessage: 'Enter FQDN/IP Address',
    },
    praPort: {
      id: 'praPort',
      defaultMessage: 'Port',
    },
    praSftpPort: {
        id: 'praSftpPort',
        defaultMessage: 'SFTP Port',
    },
    praPortHint: {
      id: 'praPortHint',
      defaultMessage: '(Range: 0-65535)'
    },
    praSftpPrivateKeyHint: {
        id: 'praSftpPrivateKeyHint',
        defaultMessage: '(Required if SFTP Password is empty)'
    },
    praSftpPassphraseHint: {
        id: 'praSftpPassphraseHint',
        defaultMessage: '(Required in case of an encrypted SFTP Private Key)'
    },
    praAdditionalSettings: {
      id: 'praAdditionalSettings',
      defaultMessage: 'Additional Settings (Authentication)',
    },
    praSftpErrorToastMessage: {
      id: 'praSftpErrorToastMessage',
      defaultMessage: 'Please double check your inputs in the File Transfer Settings section.'
    },
    praAdditionalSettingsRequirements: {
      id: 'praAdditionalSettingsRequirements',
      defaultMessage: 'Providing a username and password is optional. However, both must be provided or both must be empty.',
    },
    praUsername: {
      id: 'praUsername',
      defaultMessage: 'User Name',
    },
    praUsernamePlaceholder: {
      id: 'praUsernamePlaceholder',
      defaultMessage: 'Enter your username',
    },
    praSftpUsername: {
        id: 'praSftpUsername',
        defaultMessage: 'SFTP Username',
    },
    praSftpUsernamePlaceholder: {
        id: 'praSftpUsernamePlaceholder',
        defaultMessage: 'Enter your SFTP username'
    },
    praSftpPassword: {
        id: 'praSftpPassword',
        defaultMessage: 'SFTP Password'
    },
    praSftpPasswordPlaceholder: {
      id: 'praSftpPasswordPlaceholder',
      defaultMessage: 'Enter your SFTP password'
    },
    praSftpConfirmPasswordPlaceholder: {
        id: 'praSftpConfirmPasswordPlaceholder',
        defaultMessage: 'Confirm SFTP password'
    },
    praSftpPrivateKey: {
        id: 'praSftpPrivateKey',
        defaultMessage: 'SFTP Private Key',
    },
    praSftpPrivateKeyPlaceholder: {
        id: 'praSftpPrivateKeyPlaceholder',
        defaultMessage: 'Enter your SFTP private key'
    },
    praSftpPassphrase: {
        id: 'praSftpPassphrase',
        defaultMessage: 'SFTP Passphrase',
    },
    praSftpPassphrasePlaceholder: {
        id: 'praSftpPassphrasePlaceholder',
        defaultMessage: 'Enter your SFTP passphrase'
    },
    praSftpHostkey: {
        id: 'praSftpHostkey',
        defaultMessage: 'SFTP Host Key'
    },
    praSftpHostkeyPlaceholder: {
        id: 'praSftpHostkeyPlaceholder',
        defaultMessage: 'Enter SFTP Host Key'
    },
    praSftpHostname: {
        id: 'praSftpHostname',
        defaultMessage: 'SFTP Hostname'
    },
    praSftpHostnamePlaceholder: {
        id: 'praSftpHostnamePlaceholder',
        defaultMessage: 'Enter your SFTP hostname'
    },
    praPassword: {
      id: 'praPassword',
      defaultMessage: 'Password',
    },
    praPasswordPlaceholder: {
      id: 'praPasswordPlaceholder',
      defaultMessage: 'Enter your password',
    },
    praConfirmPasswordPlaceholder: {
      id: 'praConfirmPasswordPlaceholder',
      defaultMessage: 'Confirm password',
    },
    praHostKey: {
      id: 'praHostKey',
      defaultMessage: 'Host Key',
    },
    praHostKeyPlaceholder: {
      id: 'praHostKeyPlaceholder',
      defaultMessage: 'Enter Host Key',
    },
    praIgnoreInvalidCert: {
      id: 'praIgnoreInvalidCert',
      defaultMessage: 'Ignore invalid certificate',
    },
    praReadOnly: {
      id: 'readonly',
      defaultMessage: 'Read-Only'
    },
    praEnableFileTransfer: {
      id: 'enableFileTransfer',
      defaultMessage: 'Enable File Transfer'
    },
    praFileTransferSettings: {
        id: 'praFileTransferSettings',
        defaultMessage: 'File Transfer Settings',
    },
    praFileTransferSettingsRequirements: {
        id: 'praFileTransferSettingsRequirements',
        defaultMessage: 'SFTP User Name and Password are required for SFTP file transfers. If SFTP password is not provided, SFTP Private Key is required.',
    },
    praAuthDomainName: {
      id: 'praAuthDomainName',
      defaultMessage: 'Domain Name',
    },
    praAuthDomainNamePlaceholder: {
      id: 'praAuthDomainNamePlaceholder',
      defaultMessage: 'Enter FQDN/IP Address for Authentication',
    },
    praRequiredFields: {
      id: 'praRequiredFields',
      defaultMessage: 'Required Field',
    },
    praCancelBtn:{
      id: 'praCancelBtn',
      defaultMessage: 'Cancel',
    },
    praResetBtn: { 
      id: 'praResetBtn',
      defaultMessage: 'Reset',
    },
    praSaveBtn:{
      id: 'praSaveBtn',
      defaultMessage: 'Save',
    },
    praOkBtn:{
      id: 'praOkBtn',
      defaultMessage: 'Ok',
    },

    // Delete Apps Confirmation strings
    praDeleteAnAppTitle: {
      id: 'praDeleteAnAppTitle',
      defaultMessage: 'Delete App from Privileged Remote Access'
    },
    praDeleteAppModalMessage: {
      id: 'praDeleteAppModalMessage',
      defaultMessage: 'Are you sure you want to delete this app?'
    },
    praDeleteAppsTitle: {
      id: 'praDeleteAppsTitle',
      defaultMessage: 'Delete Apps from Privileged Remote Access'
    },
    praDeleteAppsModalMessage: {
      id: 'praDeleteAppsModalMessage',
      defaultMessage: 'Are you sure you want to delete these apps?'
    },
    // more than 3 apps we need to call a method to build the string
    // it is below;
    // stringBuilder.praDeleteManyApps(numApps)
    praAppName: {
      id: 'praName',
      defaultMessage: 'Name'
    }, 
    praDomainName: {
      id: 'PraDomainName',
      defaultMessage: 'Domain Name/FQDN'
    },
    praDeleteBtn: {
      id: 'praDeleteBtn',
      defaultMessage: 'Delete App'
    },

    // App Portal Main Page strings
    praAppPortalTitle: {
      id: 'praAppPortalTitle',
      defaultMessage: 'PRA Portal',
    },
    praPortalAccessCardTitle: {
      id: 'praAppPortalAccessTitle',
      defaultMessage: 'Privileged Remote Access Portal Settings',
    },
    praPortalTabName: {
      id: 'praPortalTabName',
      defaultMessage: 'Portal Tab Name',
    },
    praPortalDefaultProfile:  {
      id: 'praPortalDefaultProfile',
      defaultMessage: 'PRA Default Policy Profile for Custom Apps',
    },
    praPortalIcon: {
      id: 'praPortalIcon',
      defaultMessage: 'Portal Icon',
    },
    praPortalAccessURL: {
      id: 'praPortalAccessURL',
      defaultMessage: 'Portal Access URL',
    },
    praPortalEditSettingsAriaLabel: {
      id: 'praPortalEditSettingsAriaLabel',
      defaultMessage: 'Edit Settings',
    },
    praPortalGeneralSettingsTitle: {
      id: 'praPortalGeneralSettingsTitle',
      defaultMessage: 'General Settings',
    },
    praPortalAdvancedSettingsTitle: {
      id: 'praPortalAdvancedSettingsTitle',
      defaultMessage: 'Advanced Settings',
    },
    praAdvSettingsMaxUsers: {
      id: 'praAdvSettingsMaxUsers',
      defaultMessage: 'Max number of concurrent connections per user',
    },
    praAdvSettingsUserDefineApps: {
      id: 'praAdvSettingsUserDefineApps',
      defaultMessage: 'Enable users to define custom apps',
    },
    praAdvSettingsProfileForCustomApps: {
      id: 'praAdvSettingsProfileForCustomApps',
      defaultMessage: 'PRA Profile for custom apps',
    },
    praAdvSettingsUrlBasedAccess: {
      id: 'praAdvSettingsUrlBasedAccess',
      defaultMessage: 'Enable URL-based access',
    },
    praEditPortalSettingsTitle: {
      id: 'praEditPortalSettingsTitle',
      defaultMessage: 'Privileged Remote Access Portal Settings',
    },
    praEditPortalSettingsSubTitle: {
      id: 'praEditPortalSettingsSubTitle',
      defaultMessage: 'Configure portal settings for Privileged Remote Access',
    },
    praEditPortalSettingsMaxFileSizeWarning: {
      id: 'praEditPortalSettingsMaxFileSizeWarning',
      defaultMessage: `Max file size is {maxUploadFileSize}`,
    },
    praEnabled: {
      id: 'praEnabled',
      defaultMessage: 'Enabled'
    },
    praDisabled: {
      id: 'praDisabled',
      defaultMessage: 'Disabled'
    },

    praPortalUserToAppPolicyCardTitle: {
      id: 'praPortalUserToAppPolicyCardTitle',
      defaultMessage: 'User to App Policies',
    },
    praPortalAppPoliciesTitle: {
      id: 'praPortalAppPoliciesTitle',
      defaultMessage: 'App Policies',
    },
    praAddBtn: {
      id: 'praAddBtn',
      defaultMessage: 'Add'
    },
    praPortalNameCol: {
      id: 'praPortalNameCol',
      defaultMessage: 'Name'
    },
    praPortalEnabledCol: {
      id: 'praPortalEnabledCol',
      defaultMessage: 'Enabled'
    },
    praPortalUserGroupsUsersCol: {
      id: 'praPortalUserGroupsUsersCol',
      defaultMessage: 'Users and Groups'
    },
    praPortalAppGroupsAppsCol: {
      id: 'praPortalAppGroupsAppsCol',
      defaultMessage: 'Applications'
    //   defaultMessage: 'Applications and Application Groups'
    },
    praProfileCol: {
      id: 'praProfileCol',
      defaultMessage: 'PRA Profile'
    },
    praPortalDescriptionCol: {
      id: 'praPortalDescriptionCol',
      defaultMessage: 'Description'
    }, 
    
    praPortalLoadError: {
      id: 'praPortalLoadError',
      defaultMessage: 'An error occurred loading your policies.  If this error continues please contact Global Customer Support'
    },

    //  Strings for create/edit policy page
    praCreatePolicyTitle: {
      id: 'praCreatePolicy',
      defaultMessage: 'Create PRA Policy'
    },
    praEditPolicyTitle: {
      id: 'praEditPolicy',
      defaultMessage: 'Edit PRA Policy'
    },
    praDeletePolicy: {
      id: 'praDeletePolicy',
      defaultMessage: 'Delete Policy'
    },
    praPolicyGeneral: {
      id: 'praPolicyGeneral',
      defaultMessage: 'General'
    },
    praMatchCriteria: {
      id: 'PraMatchCriteria',
      defaultMessage: 'Match Criteria'
    },
    praPolicyUsersAndGroups: {
      id: 'PraPolicyUsersAndGroups',
      defaultMessage: 'USERS AND USER GROUPS'
    },
    praPolicyNamePlaceHolder: {
      id: 'praPolicyNamePlaceHolder',
      defaultMessage: 'Enter the policy name'
    },
    praPolicyDescriptionPlaceHolder: {
      id: 'PraPolicyDescriptionPlaceHolder',
      defaultMessage: 'Describe the policy'
    },
    praPolicyUsers: {
      id: 'PraPolicyUsers',
      defaultMessage: 'Users'
    },    
    praPolicyUserGroups: {
      id: 'PraPolicyUserGroups',
      defaultMessage: 'User Groups'
    },
    praPolicyAppsAndAppGroups: {
      id: 'PraPolicyAppsAndAppGroups',
      defaultMessage: 'APPLICATIONS'
    //   defaultMessage: 'APPLICATIONS AND APPLICATIONS GROUPS'
    },
    praPolicyApps: {
      id: 'PraPolicyApps',
      defaultMessage: 'Applications'
    },    
    praPolicyAppGroups: {
      id: 'PraPolicyAppGroups',
      defaultMessage: 'Application Groups'
    },
    
    praPolicyAssignProfile: {
      id: 'PraPolicyAssignProfile',
      defaultMessage: 'Assign PRA Profile'
    },
    praPolicyProfile: {
      id: 'PraPolicyProfile',
      defaultMessage: 'PRA Profile'
    },
    praPolicyProfilePlaceholder: {
      id: 'PraPolicyProfilePlaceholder',
      defaultMessage: 'Choose a PRA Profile'
    },
    praPolicyProfileTooltip: {
      id: 'praPolicyProfileTooltip',
      defaultMessage: 'For access without a defined rule'
    },
    praPolicyUsersPlaceholder: {
      id: 'praPolicyUsersPlaceholder',
      defaultMessage: 'Choose users for the policy'
    },
    praPolicyUserGroupsPlaceholder: {
      id: 'praPolicyUserGroupsPlaceholder',
      defaultMessage: 'Choose user groups for the policy'
    },
    praPolicyAppGroupsPlaceholder: {
      id: 'praPolicyAppGroupsPlaceholder',
      defaultMessage: 'Choose app groups for the policy'
    },
    praPolicyAppPlaceholder: {
      id: 'praPolicyAppPlaceholder',
      defaultMessage: 'Choose apps for the policy'
    },
    praOn: {
      id: 'praOn',
      defaultMessage: 'On'
    },
    praOff: {
      id: 'praOff',
      defaultMessage: 'Off'
    },
    praEnable: {
      id: 'praEnable',
      defaultMessage: 'enable'
    },
    praDisable: {
      id: 'praDisable',
      defaultMessage: 'disable'
    },
    praPolicyMoveUp: {
        id: 'praPolicyMoveUp',
        defaultMessage: 'Move Up',
    },
    praPolicyMoveDown: {
        id: 'praPolicyMoveDown',
        defaultMessage: 'Move Down',
    },
    praPolicyCreateSuccess: {
        id: 'praPolicyCreateSuccess',
        defaultMessage: 'Policy created successfully',
    },
    praPolicyCreateFail: {
        id: 'praPolicyCreateFail',
        defaultMessage: 'Failed to create policy',
    },
    praPolicyEditSuccess: {
        id: 'praPolicyEditSuccess',
        defaultMessage: 'Policy {policyName} edited successfully',
    },
    praPolicyEditFail: {
        id: 'praPolicyEditFail',
        defaultMessage: 'Failed to edit policy',
    },
    praPolicyDeleteSuccess: {
        id: 'praPolicyDeleteSuccess',
        defaultMessage: 'Policy {policyName} deleted successfully',
    },
    praPolicyDeleteFail: {
        id: 'praPolicyDeleteFail',
        defaultMessage: 'Failed to delete policy {policyName}',
    },
    praPolicyDeleteConfirm: {
        id: 'praPolicyDeleteConfirm',
        defaultMessage: 'Do you really want to delete the policy',
    },
    praPolicyEnableSuccess : {
      id: 'praPolicyEnableSuccess',
      defaultMessage: 'Policy {policyName} {action}d successfully'
    },
    praPolicySwitchSuccess : {
      id: 'praPolicySwitchSuccess',
      defaultMessage: 'Policy {policyName} {action}d successfully'
    },
    praPolicySwitchFailure : {
      id: 'praPolicySwitchFailure',
      defaultMessage: 'Failed to {action} policy {policyName}'
    },
    praPolicyDeleteAriaLabel: {
      id: 'praPolicyDeleteAriaLabel',
      defaultMessage: 'Delete Policy'
    },
    praPolicyEditAriaLabel: {
      id: 'praPolicyEditAriaLabel',
      defaultMessage: 'Edit Policy'
    },
    praUsersAndGroupsValidation: {
      id: 'praUsersAndGroupsValidation',
      defaultMessage: 'At least one user or group should be selected'
    },

    // Strings for App Groups pages
      praAppGroups: {
        id: 'praAppGroups',
        defaultMessage: 'Application Groups'
      },
      praAppGroupsCol: {
      id: 'praAppGroupsCol',
      defaultMessage: 'Group Names'
    },
    praApplicationCol: {
      id: 'praApplicationCol',
      defaultMessage: 'Applications'
    },
    praAddAppGroupTitle:  {
      id: 'praAddAppGroup',
      defaultMessage: 'Add Application Group',
    },
    praEditAppGroup:   {
      id: 'praEditApplication',
      defaultMessage: 'Edit Application Group',
    },
    praDeleteAppGroup:   {
      id: 'praDeleteApplication',
      defaultMessage: 'Delete Application Group',
    },
    praAppDescription: {
      id: 'praAppColDescription',
      defaultMessage: 'Description'
    },
    praApplicationLabel: {
      id: 'praApplicationLabel',
      defaultMessage: 'Applications'
    },
    praNameLabel: {
      id: 'praNameLabel',
      defaultMessage: 'Name'
    },
    praAppGroupDeleteSuccess: {
      id: 'praAppGroupDeleteSuccess',
      defaultMessage: `App Group "{groupName}" deleted successfully`,
    },
    praAppGroupDeleteFail: {
      id: 'praAppGroupDeleteFail',
      defaultMessage: `Failed to delete App Group "{groupName}"`,
    },
    praAppGroupDeletionModalTitle: {
      id: 'praAppGroupDeletionModalTitle',
      defaultMessage: 'Confirm deletion',
    },
    praAppGroupDeleteConfirm: {
      id: 'praAppGroupDeleteConfirm',
      defaultMessage: 'Do you really want to delete the app group',
  },
  praAppGroupCreateFailNameInUse: {
    id: 'praAppGroupCreateFailNameInUse',
    defaultMessage: 'The app group name is already in use.'
  },

    // Strings for PRA status page.
    praStatusOverviewTitle: {
      id: 'praStatusOverviewTitle',
      defaultMessage: 'PRA Overview Status'
    },
    praStatusTitle: {
      id: 'praStatusTitle',
      defaultMessage: 'Privileged Remote Access Status'
    },
    praTenantEnabled: {
      id: 'PraTenantEnabled',
      defaultMessage: 'PRA Enabled'
    },
    praAuthInfo: {
      id: 'PraAuthInfo',
      defaultMessage: 'PRA Auth Profile Name'
    },
    praCieDirectory: {
      id: 'PraCieDirectory',
      defaultMessage: 'PRA CIE Directory'
    },
    praRegion: {
      id: 'PraRegion',
      defaultMessage: 'CIE Region'
    },
    praLocations: {
      id: 'PraLocations',
      defaultMessage: 'PRA Locations'
    },
    praLocationError: {
      id: 'PraLocations',
      defaultMessage: 'Location information is not available at this time'
    },
    praEnableToggleText: {
      id: 'praEnableToggleText',
      defaultMessage: "Enabled Priviledged Remote Access",
    },
    praDisableToggleText: {
      id: 'praDisableToggleText',
      defaultMessage: "Disabled Priviledged Remote Access",
    },
    praDisableConfirmation: {
      id: 'praDisableConfirmation',
      defaultMessage: 'Are you sure you want to disable Privileged Remote Access?'
    },
    praEditMainPraSettingsTitle: {
      id: 'praEditMainPraSettingsTitle',
      defaultMessage: 'Edit PRA Settings'
    },
    praConfirmPraDisableTitle: {
      id: 'praConfirmPraDisableTitle',
      defaultMessage: 'Disable Privileged Remote Access',
    },
    praSettingsEditSuccess: {
      id: 'praSettingsEditSuccess',
      defaultMessage: 'PRA Settings updated successfully'
  },
  praSettingsEditFail: {
      id: 'praProfileEditFail',
      defaultMessage: 'Failed to update PRA Settings'
  },

    // specific error messages
    ErrorDupAppName: { // 402 - 1010
      id: 'ErrorDupAppName',
      defaultMessage: "The Application Name, Destination, or Port is not unique and is already in use.  Please ensure that all of these are unique from those in other apps.",
    },
    
    UnKnownError: {
      id: 'UnKnownError',
      defaultMessage: "An unknown error has occurred. Please try again later and if the error persists contact Global Customer Support.",
    },

    // generic error messages
    TenantDoesNotExist: { // 403
      id: 'appmgmtErrCBM',
      defaultMessage: "The tenant cannot be found. Please contact Global Customer Support.",
    },
    serverDataChanged: {  // 404
      id: 'appmgmtErrCBM',
      defaultMessage: "The tenant cannot be found.  Please contact Global Customer Support.",
    },
    serverError: {    // 500
      id: 'appmgmtErrCBM',
      defaultMessage: "A system error has occurred. Please try again later and if the error persists contact Global Customer Support.",
    },
    serverInitError: {    // 500{
      id: 'serverInitError',
      defaultMessage: "A system error has occurred. Unable to initialize system. Please contact Global Customer Support.",
    }
}

export const stringBuilder = {
  praDeleteManyApps: (numApps: number): MessageDescriptor => {
    const msg  = `Are you sure you want to delete ${numApps} app?`;

    return {
      id: 'praDeleteManyAppModalMessage',
      defaultMessage: msg
    }
  },
}
